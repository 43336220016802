var utils = {
  touchClick : (('ontouchstart' in window) || (window.DocumentTouch && document instanceof DocumentTouch)) ? 'touchstart' : 'click',
  getViewPort : function() {
     var viewPortWidth, viewPortHeight;

      // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
      if (typeof window.innerWidth != 'undefined') {
        viewPortWidth = window.innerWidth;
        viewPortHeight = window.innerHeight;
      }

     // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
      else if (typeof document.documentElement != 'undefined'
      && typeof document.documentElement.clientWidth !=
      'undefined' && document.documentElement.clientWidth != 0) {
         viewPortWidth = document.documentElement.clientWidth;
         viewPortHeight = document.documentElement.clientHeight
      }

      // older versions of IE
      else {
        viewPortWidth = document.getElementsByTagName('body')[0].clientWidth;
        viewPortHeight = document.getElementsByTagName('body')[0].clientHeight;
      }
      return [viewPortWidth, viewPortHeight];
   },
   calcPerc : function(perc,el){
     return (perc / 100) * el;
   },
   getCasualNumber : function(min,max){
     return (Math.floor(Math.random() * (max - min + 1)) + min);
   },
   getRandomInt: function(min, max) {
       return ~~(Math.random() * (max - min + 1)) + min;
   },
  addClass : function(el, className) {
    if (el.classList)
      el.classList.add(className)
    else if (!utils.hasClass(el, className))
      el.className += " " + className
    },
    hasClass: function(el, className) {
      if (el.classList) {
        return el.classList.contains(className);
      } else {
        return !!el.className.match(
          new RegExp("(\\s|^)" + className + "(\\s|$)")
        );
      }
    },
    removeClass : function(el, className) {
         if (el.classList)
              el.classList.remove(className)
         else if (utils.hasClass(el, className)) {
              var reg = new RegExp('(\\s|^)' + className + '(\\s|$)')
              el.className=el.className.replace(reg, ' ')
          }
    },
    getOffset: function(el) {
      var _x = 0;
      var _y = 0;
      while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
          _x += el.offsetLeft - el.scrollLeft;
          _y += el.offsetTop - el.scrollTop;
          el = el.offsetParent;
      }
      return { top: _y, left: _x };
    },
    getTranslateX : function(el) {
      var style = window.getComputedStyle(el);
      var matrix = new WebKitCSSMatrix(style.webkitTransform);
      return matrix.m41;
    },
    setTargetPosition: function(ratio,target){
          var viewport = utils.getViewPort();

          if(target){
            var currentRatio = window.innerWidth / window.innerHeight;

              if(currentRatio < ratio){
                var targetWidth = window.innerWidth;
                var targetHeight = viewport[0] / ratio;
              }
              else if(currentRatio > ratio){
                var targetWidth = window.innerHeight * ratio;
                var targetHeight = window.innerHeight;
              }
              target.style.height = "" + targetHeight + "px";
              target.style.width = "" + targetWidth + "px";
          }

        },
      preLoad : function(el){
        var elData = el.getAttribute('data-load');
        var dataType = elData.split('.');
        var data;
        var promise = new Promise(function(resolve){

          if(dataType == 'mp3' || 'ogg'){
            data = new Audio(elData);
          }else{
            data = new Image(elData);
          }

          data.addEventListener('loadeddata', function(){
            resolve(el = data);
          });
      });
    },
    ajax : function(url,type){
      var promise = new Promise(function(resolve){

        if(XMLHttpRequest){
          var request = new XMLHttpRequest();

           request.open(type, url, true);
           request.onreadystatechange = function(){
             if (request.readyState == XMLHttpRequest.DONE ) {
                if (request.status == 200) {
                  var data = JSON.parse(this.responseText);
                  resolve(el = data);
                }
                else if (request.status == 400) {
                   console.log('There was an error 400');
                }
                else {
                    console.log('something else other than 200 was returned');
                }
             }
           };
           request.send();

        }
      });
    }
}

module.exports = utils;
