var utils= require('./component/utils.js');

(function(){
  'use strict';

  // caching some elements and stuff
  var app = {
      sections: document.getElementsByClassName('hidden-section'),
      logo: document.getElementById('main-logo'),
      fullPage: document.getElementById('fullpage'),
      header: document.getElementById('header'),
      parallaxElements : document.getElementsByClassName('parallax__layer'),
      hero: document.getElementById('hero'),
      introText : document.getElementById('intro-text'),
      infoBox: document.getElementById('info-box'),
      teamWrap : document.getElementById('team-wrapper'),
      sectionBtn: document.getElementsByClassName('hero-section-btn'),
      heroSection: document.getElementsByClassName('hero-section'),
      splashBtn: document.getElementById('btn-splash'),
      backBtn : document.getElementById('back-btn'),
      overlayBtn: document.getElementById('overlay-btn'),
      overlayVoice: document.getElementsByClassName('overlay__voice'),
      overlayPages: document.getElementsByClassName('page'),
      pageLink: document.getElementsByClassName('page-link'),
      pageService: document.getElementsByClassName('service'),
      mobile: false,
      stopAnimation: null,
      stopExplosion: null
    };

  // CANVAS
  var canvas = {
    reference : document.getElementById('reference'),
    interactive : document.getElementById('interactive')
  };

  // init canvas - out of init function
  canvas.contextInteractive = canvas.interactive.getContext("2d");
  canvas.contextReference = canvas.reference.getContext("2d");
  canvas.width = canvas.interactive.width = canvas.reference.width = window.innerWidth;
  canvas.height = canvas.interactive.height = canvas.reference.height = window.innerHeight;

  var el;
  var particleAttributes;
  var elDimensions;
  var center;
  var elLocation;
  var mouse;
  app.stopAnimation = false;
  app.stopExplosion = false;
  app.justExplode = false;


  var particleArr = [];


  // DOM ready
  function init(){
    introManager();
    dataManager();
  }

  // DATA FROM AJAX and 'TEMPLATING'

  // ajax call for data/testi.json

  function dataManager(){

    // get data from testi.json
    if(XMLHttpRequest){
      var request = new XMLHttpRequest();
      if("withCredentials" in request)
      {
       // Firefox 3.5 and Safari 4
       request.open('GET', 'data/testi.json', true);
       request.onreadystatechange = function(){
         if (request.readyState == XMLHttpRequest.DONE ) {
            if (request.status == 200) {
              // succes - manage data
              app.data = JSON.parse(this.responseText);
              for(var i =0; i< app.data.length; i++){
                pageManager(app.data[i]);
              }

            }
            else if (request.status == 400) {
               console.log('There was an error 400');
            }
            else {
                console.log('something else other than 200 was returned');
            }
         }
       };
       request.send();
      }
      else if (XDomainRequest)
      {
       // IE8
       var xdr = new XDomainRequest();
       xdr.open("get", 'data/testi.json');
       xdr.send();

       // handle XDR responses -- not shown here :-)
     }

     // This version of XHR does not support CORS
     // Handle accordingly
    }
  }

  // inject data in page
  function pageManager(data){
    for (var key in data){
        var attrName = key;
        var attrValue = data[key];

        // set data to client block - name
        if(attrName == 'splashPage'){
          document.getElementById('intro-text').innerHTML = attrValue.titolo;
        }
        else if(attrName == 'sezione2'){
          document.getElementById('second-text').innerHTML = attrValue.paragrafo;
        }
        else if(attrName == 'sezione3'){
          document.getElementById('third-text').innerHTML = attrValue.paragrafo;
        }
        else if(attrName == 'sezione5'){
          //document.getElementById('mail-text').innerHTML = attrValue.paragrafo;
          document.getElementById('footer-text').innerHTML = attrValue.legali;
        }
        else if(attrName == 'whoPage'){
          document.getElementById('who-text').innerHTML = attrValue.paragrafo;
          document.getElementById('looking-text').innerHTML = attrValue.lookingForUs;
          document.getElementById('fashion-text').innerHTML = attrValue.serviceList;
        }
        else if(attrName == 'whatPage'){
          document.getElementById('what-text').innerHTML = attrValue.paragrafo;
          document.getElementById('advisory-text').innerHTML = attrValue.advisoryList;
          document.getElementById('incubation-text').innerHTML = attrValue.incubationList;
          document.getElementById('partnerships-text').innerHTML = attrValue.partnershipList;
        }
        else if(attrName == 'whyPage'){
          document.getElementById('why-text').innerHTML = attrValue.paragrafo;
        }

    }
    listeners();
  }


  // PARTICLE

  // set particle setting , mobile or desk - and then start explosion at user click or with timer

  function introManager(){
    app.introText.addEventListener(utils.touchClick,introExplosion);

      if(utils.touchClick == 'touchstart' || window.innerWidth < 650){
        utils.addClass(document.body,'mobile');
        app.mobile = true;
        el = document.getElementById('logo-m');
        elDimensions = {
                x: 105,
                y: 128
            };
        particleAttributes  = {
                friction: 0.95,
                ease: 0.02,
                spacing: 1,
                size: 1
            };
      }else{
        el = document.getElementById('logo');
        elDimensions = {
                x: 150,
                y: 185
            };
        particleAttributes  = {
                friction: 0.95,
                ease: 0.02,
                spacing: 1,
                size: 1
            };
      }
      center = {
          x: canvas.width / 2,
          y: canvas.height / 2
      };

      elLocation = {
          x: center.x - (elDimensions.x / 2),
          y: center.y - (elDimensions.y / 2)
      };

      mouse = {
          radius: Math.pow(30, 2),
          x: 0,
          y: 0
      };

      explosionDelay();

      function explosionDelay(){
        var delay;
        if(!app.mobile){
          delay = 6500;
        }else{
          delay = 6550;
        }
        setTimeout(function(){
          if(!app.justExplode){
            makeExplode();
          }
        },delay);
      }

      function introExplosion(){
        app.justExplode = true;
        makeExplode();
      }

      function makeExplode(){
        app.stopRain = true;
        app.introText.style.display = 'none';
        drawReference();
        nucleus();
        initExplosion();
        addListener();
        setTimeout(function(){
          utils.addClass(app.splashBtn,'show');
        },6500);
      }

    }

  // PARTICLE ANIMATION & INTERACTION

  // rain - particle fall from top

  function initRain(){
    rain();
    app.stopAnimation = false;
    animate();
  }

  function rain(){
    for(var i = 0; i < particleArr.length; i++) {
        var p = particleArr[i];
        p.rainy();
    }
    render();
  }

  function stopRain(){
    app.stopAnimation = true;
    removeListener();
  }

  //  particle start to center of page

  function nucleus(){
    for(var i = 0; i < particleArr.length; i++) {
        var p = particleArr[i];
        p.centered();
    }
    render();
  }

  //  particle are push around the page randomly

  function initExplosion(){
    app.stopExplosion = false;
    animateExplode();
    setTimeout(function(){
      app.stopExplosion = true;
    },2000);
  }

  function animateExplode(){
    if(!app.stopExplosion){
      explode();
      render();
      requestAnimationFrame(animateExplode);
    }else{
      cancelAnimationFrame(animateExplode);
    }
  }

  function explode() {
     for(var i = 0; i < particleArr.length; i++) {
         var p = particleArr[i];
         p.random();
     }
  }

  // particle interaction listeners - desk & mobile

  function addListener(){
    if(utils.touchClick == 'click'){
      document.body.addEventListener("mousemove", onMove);
    }else{
      document.body.addEventListener("touchstart", onStart, false);

      document.body.addEventListener("touchmove", onMove, false);

      document.body.addEventListener("touchend", onEnd, false);
    }
  }

  function removeListener(){
    if(utils.touchClick == 'click'){
      document.body.removeEventListener("mousemove", onMove);
    }else{
      document.body.removeEventListener("touchstart", onStart, false);

      document.body.removeEventListener("touchmove", onMove, false);

      document.body.removeEventListener("touchend", onEnd, false);
    }
  }

  function onStart(e){
    mouse.x = e.changedTouches[0].clientX;
    mouse.y = e.changedTouches[0].clientY;
  }

  function onMove(e){
    if(utils.touchClick == 'click'){
      mouse.x = e.clientX;
      mouse.y = e.clientY;
    }else{
      e.preventDefault();
      mouse.x = e.targetTouches[0].clientX;
      mouse.y = e.targetTouches[0].clientY;
    }

  }

  function onEnd(e){
    e.preventDefault();
    mouse.x = 0;
    mouse.y = 0;
  }

  // mouse interaction animation

  function animate() {
    if(!app.stopAnimation){
      update();
      render();
      requestAnimationFrame(animate);
    }
    else{
      cancelAnimationFrame(animate);
    }
  }

  //upadate particle state - return at origin

  function update() {
       for(var i = 0; i < particleArr.length; i++) {
           var p = particleArr[i];
           p.update();
       }
  };

  // particle render in canvas

  function render() {
       canvas.contextInteractive.clearRect(0, 0, canvas.width, canvas.height);
       for(var i = 0; i < particleArr.length; i++) {
           var p = particleArr[i];
           canvas.contextInteractive.fillStyle = "#fff";
           canvas.contextInteractive.fillRect(p.x, p.y, particleAttributes.size, particleAttributes.size);
       }
  };

  // particle init

  class Particle {

    constructor(data){
      this.x = this.originX = data.x;
      this.y = this.originY = data.y;
      this.rx = 0;
      this.ry = 0;
      this.vx = 0;
      this.vy = 0;
      this.force = 0;
      this.angle = 0;
      this.Rx = utils.getRandomInt(-canvas.width,(canvas.width * 2));
      this.Ry = utils.getRandomInt(-canvas.height,(canvas.height * 2));
      this.distance = 0;
      this.colors = data.colors;
    }
    random(){
      this.x += (this.vx *= particleAttributes.friction) + (this.Rx - this.x) * particleAttributes.ease;
      this.y += (this.vy *= particleAttributes.friction) + (this.Ry - this.y) * particleAttributes.ease;
    }
    centered(){
      var variable = utils.getRandomInt(2,20);
      this.x = utils.getRandomInt((canvas.width / 2) - variable,(canvas.width / 2) + variable);
      this.y = utils.getRandomInt((canvas.height / 2) - variable,(canvas.height / 2) + variable);
    }
    rainy(){
      this.x = utils.getRandomInt((canvas.width / 2) - 200,(canvas.width / 2) + 200);
      this.y = utils.getRandomInt(-10,-2000);
    }
    update() {
        this.rx = mouse.x - this.x;
        this.ry = mouse.y - this.y;
        this.distance = this.rx * this.rx + this.ry * this.ry;
        this.force = -mouse.radius / this.distance;
        // if contact with mouse
        if(this.distance < mouse.radius) {
             this.angle = Math.atan2(this.ry, this.rx);
             this.vx += this.force * Math.cos(this.angle);
             this.vy += this.force * Math.sin(this.angle);
        }
        this.x += (this.vx *= particleAttributes.friction) + (this.originX - this.x) * particleAttributes.ease;
        this.y += (this.vy *= particleAttributes.friction) + (this.originY - this.y) * particleAttributes.ease;

    };
  }

  function drawReference(){
    canvas.contextReference.drawImage(el,elLocation.x, elLocation.y);
    var pixels = canvas.contextReference.getImageData(0, 0, canvas.width, canvas.height).data;
    var index;
    for(var y = 0; y < canvas.height; y += particleAttributes.spacing) {
          for(var x = 0; x < canvas.width; x += particleAttributes.spacing) {
              index = (y * canvas.width + x) * 4;

              if(pixels[++index] > 0) {
                var particleData = {
                  x: x,
                  y: y,
                  colors :{
                    red: pixels[index +3],
                    green: pixels[index + 3],
                    blue: pixels[index + 3],
                    alpha: 8
                  }
                }
                particleArr.push(new Particle(particleData));

              }
          }
      }
  }

  function destroyParticles() {
    for (var i = 0; i < particleArr.length; i++) {
      particleArr[i] = null;
      delete particleArr[i];
    }
    particleArr = [];
  }


  // GENERAL LISTENERS

  function listeners(){

    app.overlayBtn.addEventListener(utils.touchClick, overlayManager);
    window.addEventListener('resize', onResize, false);

    // overlay state handler

    function overlayManager(){
        if(utils.hasClass(document.body,'on-service')){
          utils.removeClass(document.body,'on-service');
          resetService();
        }
        else{
          if(utils.hasClass(document.body,'on-overlay')){
            utils.removeClass(document.body,'on-overlay');
          }
          else{
            utils.addClass(document.body,'on-overlay');
          }
      }
    }

    // menu voice handler

    for(var i = 0; i < app.overlayVoice.length; i++){
      app.overlayVoice[i].addEventListener(utils.touchClick,function(e){
        e.preventDefault();
        resetPages();
        var attribute = document.getElementsByClassName(this.getAttribute('data-anchor'));
        attribute[0].style.zIndex = 990;
        utils.addClass(attribute[0],'active');
        utils.addClass(document.body,'on-pages');
        utils.removeClass(document.body,'on-overlay');
        $.fn.fullpage.setAllowScrolling(false);
      });
    }

    // internal link in overlay handler

    for(var i = 0; i < app.pageLink.length; i++){
      app.pageLink[i].addEventListener(utils.touchClick,function(e){
        e.preventDefault();
        resetService();
        var attribute = document.getElementsByClassName(this.getAttribute('data-anchor'));
        attribute[0].style.zIndex = 990;
        utils.addClass(document.body,'on-service');
        utils.addClass(attribute[0],'active');
      });
    }

    // logo reset page position on hero page

    app.logo.addEventListener(utils.touchClick,function(){
      resetPages();
      $.fn.fullpage.silentMoveTo(2, 0)
      utils.removeClass(document.body,'on-pages');
      utils.removeClass(document.body,'on-overlay');
      utils.removeClass(document.body,'on-service');
      $.fn.fullpage.setAllowScrolling(true);
    });

    // on resize reset app and canvas render

    function onResize() {
      app.stopAnimation = true;
      canvas.contextReference.clearRect(0, 0, canvas.width, canvas.height);
      canvas.contextInteractive.clearRect(0, 0, canvas.width, canvas.height);
      destroyParticles();
      removeListener();
      canvas.width = canvas.interactive.width = canvas.reference.width = window.innerWidth;
      canvas.height = canvas.interactive.height = canvas.reference.height = window.innerHeight;
      center = {
          x: canvas.width / 2,
          y: canvas.height / 2
      };

      elLocation = {
          x: center.x - elDimensions.x / 2,
          y: center.y - elDimensions.y / 2
      };
      drawReference();
      app.stopAnimation = false;
      initRain();
      addListener();
      if(utils.touchClick == 'touchstart' || window.innerWidth < 650){
        app.mobile = true;
        utils.addClass(document.body,'mobile');
      }else{
        app.mobile = false;
        utils.removeClass(document.body,'mobile');
      }
   }
  }

  function resetPages(){
    for(var x = 0; x < app.overlayPages.length; x++){
      utils.removeClass(app.overlayPages[x],'active');
      app.overlayPages[x].style.zIndex = 0;
    }
  }

  function resetService(){
    for(var x = 0; x < app.pageService.length; x++){
      utils.removeClass(app.pageService[x],'active');
      app.pageService[x].style.zIndex = 0;
    }
  }

  // FULLPAGE PLUGIN - fullpage.js

  function initFullPage(){
      $('#fullpage').fullpage({
        anchors: ['splash','hero','second','third','head','footer'],
        scrollingSpeed: 800,
        recordHistory: false,
        afterRender: function(){
          app.splashBtn.addEventListener(utils.touchClick, function(){
            leaveSplash();
          });
        },
        onLeave: function(index, nextIndex, direction){
          if(index == 1 && direction == 'down'){
            stopRain();
            initExplosion()
            removeListener();
            utils.removeClass(document.body,'on-splash');
          }
          if(index == 2 && direction == 'up'){
            addListener();
            utils.addClass(app.splashBtn,'show');
            setTimeout(function(){
              enterSplash();
            },1000);
          }
        },
        afterLoad: function(anchorLink, index){
          history.pushState("", document.title, window.location.pathname);
          if(anchorLink == 'splash'){
            initRain();
            $.fn.fullpage.setAllowScrolling(false);
            utils.addClass(document.body,'on-splash');
          }
        },
        stopScroll: function(){
          $.fn.fullpage.setAllowScrolling(false);
        }

    });

    $.fn.fullpage.setAllowScrolling(false);
  }

  // SPLASH SECTION BEHAVIOUR

  function enterSplash(){
    initRain();
    $.fn.fullpage.setAllowScrolling(false);
    utils.addClass(document.body,'on-splash');
    setTimeout(function(){
      utils.addClass(app.splashBtn,'show');
    },1000);
  }

  function leaveSplash(){
    stopRain();
    initExplosion();
    removeListener();
    utils.removeClass(app.splashBtn,'show');
    utils.removeClass(document.body,'on-splash');
    setTimeout(function(){
      $.fn.fullpage.silentMoveTo(2, 0);
    },1000);
    $.fn.fullpage.setAllowScrolling(true);
  }

  $(document).ready(function(){
    init();
    initFullPage();
  });

})();
